$(document).foundation();

var swiper = new Swiper('.slider_beeld', {
	speed: 900,
	loop: true,
	effect: 'fade',
	centeredSlides: true,
	autoplay: 2500,
//	simulateTouch: false,
	lazyLoading: true
});

new WOW().init();